import React from "react"
import GroupItem from './group_item';

const isBrowser = typeof window !== "undefined";

class GroupGrid extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    if (isBrowser) {
      let group_data = this.props.data.map(group_node => {
        let id = group_node.context.data.drupal_internal__nid
        let img = group_node.images_single_group;
        return (
          <GroupItem key={id} group={group_node.context.data} image={img} location={this.props.location}></GroupItem>
        )
      })

      this.setState({
        data: group_data,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true
    }
    return false
  }

  componentDidUpdate(prevProps, prevState) {
    this.props.dataReady();
  }

  render() {
    return (
      <div className="column__wrapper">
        { this.state.data }
      </div>
    );
  }
}

export default GroupGrid
