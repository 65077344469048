import React, { Component } from 'react';
import { Link } from "gatsby"

import Slider from "../components/slider";

class GroupItem extends Component {

  render() {
    let group = this.props.group;
    let id = group.drupal_internal__nid;
    let image = this.props.image;
    let group_img;
    let pathPrefix = '';
    let path = this.props.location.pathname;

    let sanitized_title = group.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove accents
      .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
      .replace(/--+/g, '-')	// Replaces multiple hyphens by one hyphen
      .replace(/(^-+|-+$)/, '') // Remove extra hyphens from beginning or end of the string
      .toLowerCase(); // Lowercase.
    let alias = `/groepen/${sanitized_title}/`

    if (path !== undefined && path.split('/').includes("amp")) {
      pathPrefix = '/amp'
    }

    if (typeof image !== 'undefined' && image !== null && Array.isArray(image)) {
      if (image[0].childImageSharp !== null) {
        group_img = image
      }
    }

    return (
      <Link key={id} className="column__item" to={pathPrefix + alias} state={{ prevPath: `${pathPrefix}/groepen/` }}>
        <div className="column__item--image">
          {group_img &&
            <Slider images={group_img} hideArrows={true}></Slider>
          }
        </div>
        <div className="column__item--inner">
          <span className="column__item--title">{group.title}</span>
        </div>
      </Link>
    );
  }
}

export default GroupItem;
