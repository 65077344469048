import React, { Component } from "react"
import { graphql } from "gatsby"
import Seo from '../components/seo';

import "../components/css/groups.css";
import GroupGrid from "../components/groupGrid";

const isBrowser = typeof window !== "undefined";

class Groepen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollPosSet: false,
    };
    this.setScrollReady = this.setScrollReady.bind(this);
    this.setScrollPos = this.setScrollPos.bind(this);
  }

  componentWillUnmount() {
    if (isBrowser) {
      window.removeEventListener('scroll', this.setScrollPos, true)
    }
  }

  setScrollReady() {
    if (isBrowser) {
      // Restore scroll position.
      if (!this.state.scrollPosSet) {

        this.setState({
          scrollPosSet: true,
        }, () => {
          if (localStorage.getItem('scrollPosGroup')) {
            // Update layout grid.
            let event = new CustomEvent('resize');
            document.scrollingElement.dispatchEvent(event);

            // Scroll.
            document.body.scrollTop = localStorage.getItem('scrollPosGroup');
            document.documentElement.scrollTop = localStorage.getItem('scrollPosGroup');
          }
          window.addEventListener('scroll', this.setScrollPos, true)
        });

      }
    }
  }

  setScrollPos(e) {
    if (e.target.scrollingElement) {
      localStorage.setItem('scrollPosGroup', e.target.scrollingElement.scrollTop)
    }
    else {
      localStorage.setItem('scrollPosGroup', e.target.scrollTop)
    }
  }

  render() {
    let group_nodes = this.props.data.allSitePage.nodes;

    return (
      <>
        <Seo
          title="Groepen"
          pathname="/groepen/"
          article={false}
        />

        <GroupGrid dataReady={this.setScrollReady} data={group_nodes} location={this.props.location}></GroupGrid>
      </>
    );
  }
}

export const query = graphql`
query GroupQuery {
  allSitePage(filter: {context: {amp: {eq: false}, bundle: {eq: "Group"}}}) {
    nodes {
      images_single_group {
        childImageSharp {
          gatsbyImageData(
            width: 560
            height: 360
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP]
          )
        }
      }
      context {
        data {
          drupal_internal__nid
          title
        }
      }
    }
  }
}
`

export default Groepen
